import { List, Map, Set } from 'immutable';
import { useState } from 'react';
import { KindsIndex } from 'redux/state/kinds';

import { Button } from '@alkem/react-ui-button';

import { ApplicableKindsModal } from './applicable-kinds-modal';
import { ApplicableKindsSummary } from './applicable-kinds-summary/applicable-kinds-summary';
import './applicable-kinds.scss';

export const ApplicableKinds = ({
  kinds,
  applicableKinds,
  forbiddenKinds,
  defaultApplicableKinds,
  onChange,
  compact = false,
  disabled = false,
}: {
  kinds: KindsIndex;
  applicableKinds: List<Map<string, number>>;
  forbiddenKinds: Set<number>;
  defaultApplicableKinds: List<Map<string, number>>;
  onChange: (kinds: List<Map<string, number>>) => void;
  compact?: boolean;
  disabled?: boolean;
}) => {
  const [modalState, setModalState] = useState<boolean>(false);

  const toggleModal = (modalState: boolean) => {
    setModalState(modalState);
  };

  const handleModalClosed = (kinds?: List<Map<string, number>>) => {
    toggleModal(false);
    if (kinds) {
      onChange(kinds);
    }
  };

  // If you change the selector you can have kinds checked that are disabled in the modal,
  // here we only want enabled and checked kinds to show a live summary of trully applicable kinds
  const filteredApplicableKinds = applicableKinds
    .filter((kind) => {
      return !!(
        kind &&
        !!kind.get('id') &&
        !forbiddenKinds.includes(kind.get('id') as number)
      );
    })
    .toList();

  const kindsAreManuallySelected = !!filteredApplicableKinds.size;

  const applicableKindsToDisplayInSummary = filteredApplicableKinds.size
    ? filteredApplicableKinds
    : defaultApplicableKinds;

  return (
    <div className="ApplicableKinds">
      {compact ? (
        <Button
          type="button"
          content={`Kinds (${applicableKindsToDisplayInSummary.size})`}
          onClick={() => toggleModal(true)}
          secondary
          disabled={disabled}
        />
      ) : (
        <>
          <ApplicableKindsSummary
            applicableKinds={applicableKindsToDisplayInSummary}
            kinds={kinds}
            kindsAreManuallySelected={kindsAreManuallySelected}
          />
          {!disabled && (
            <Button
              type="button"
              secondary
              content="Edit/view"
              onClick={() => toggleModal(true)}
            />
          )}
        </>
      )}
      {modalState && (
        <ApplicableKindsModal
          applicableKinds={applicableKinds}
          forbiddenKinds={forbiddenKinds}
          onClose={handleModalClosed}
        />
      )}
    </div>
  );
};
