import { List, Map, Set, fromJS } from 'immutable';
import { useState } from 'react';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';

import KindsTree from 'components/kind-tree';

export const ApplicableKindsModal = ({
  applicableKinds,
  forbiddenKinds,
  onClose,
}: {
  applicableKinds: List<Map<string, number>>;
  forbiddenKinds: Set<number>;
  onClose: (applicableKinds?: List<Map<string, number>>) => void;
}) => {
  const [applicableKindsState, setApplicableKindsState] =
    useState<List<Map<string, number>>>(applicableKinds);

  const update = (value) => {
    setApplicableKindsState(
      fromJS(
        Object.entries(value)
          .filter(
            (entry) =>
              entry[1] && !forbiddenKinds.toJS().includes(parseInt(entry[0])),
          )
          .map((entry) => ({ id: parseInt(entry[0]) })),
      ),
    );
  };

  const computeSelected = () => {
    const selected = {};
    applicableKindsState.forEach((kind?: Map<string, number>) => {
      if (kind && !!kind.get('id')) {
        selected[kind.get('id') as number] = true;
      }
    });
    return selected;
  };

  const unselectAllButton = (
    <Button
      type="button"
      secondary
      content="Unselect all"
      onClick={() => setApplicableKindsState(fromJS([]))}
    />
  );

  return (
    <Modal
      title="Select applicable kinds"
      confirmButtonText="Apply"
      onConfirm={() => onClose(applicableKindsState)}
      onClose={() => onClose()}
      additionalFooterContent={unselectAllButton}
    >
      <KindsTree
        onSelectionChange={update}
        selection={computeSelected()}
        revealSelectedPaths
        forbiddenKinds={forbiddenKinds}
      />
    </Modal>
  );
};
