import { List } from 'immutable';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useDebounce } from '@alkem/react-hooks';

import { LegacyAutocomplete } from 'components/autocomplete';
import { getFilteredOrganizations } from 'modules/user-list/actions/organizations';

const TYPING_DELAY = 500;
const MAX_RESULTS = 50;

type Organization = Map<string, any>;
type Item = {
  label: string;
  value: any;
  key: string;
} | null;

const mapOrganizationToItem = (org?: Organization) =>
  org
    ? {
        label: `${org.get('nameLegal')} - ${org.get('id')}`,
        value: org.get('id'),
        key: `org-${org.get('id')}`,
      }
    : null;

export function OrganizationsAutocomplete(props) {
  const dispatch: (any) => List<Organization> = useDispatch();
  const [list, setList] = useState<Item[]>([]);
  const [search, setSearch] = useState('');

  useDebounce(search, TYPING_DELAY, async (s) => {
    if (!s || s.length <= 1) {
      setList([]);
      return;
    }
    const organizations = await dispatch(
      getFilteredOrganizations(s, MAX_RESULTS),
    );
    setList(organizations.map(mapOrganizationToItem).toJS());
  });

  return <LegacyAutocomplete onSearch={setSearch} list={list} {...props} />;
}
