import { Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from './events';

export const TRANSACTION_EXPLORER_DASHBOARD_STORE_KEY = 'transaction-explorer';

const initialState = fromJS({
  transactionId: null,
  links: [],
  tracesLoaded: {},
  transactions: {},
  selected: {},
  layers: [],
  modal: {
    show: false,
    payload: {},
  },
});

export default createReducer(initialState, {
  [events.LOAD_TRANSACTION_ID]: (_, { transactionId }) =>
    initialState.set('transactionId', transactionId),
  [events.LINKS_LOADED]: (state, { links }) =>
    state
      .set('links', (state.get('links') as any).concat(links))
      .withMutations((newState) => {
        links
          .filter((link) => link.get('type') === 'trace')
          .forEach((link) => {
            newState
              .setIn(['tracesLoaded', link.get('transaction_id')], true)
              .setIn(['tracesLoaded', link.get('parent_id')], true);
          });
      }),
  [events.TRANSACTIONS_LOADED]: (state, { transactions }) =>
    state.set(
      'transactions',
      (state.get('transactions') as any).merge(
        // TODO: pick only the needed keys.
        transactions.reduce((m, trx) => m.set(trx.get('id'), trx), Map()),
      ),
    ),
  [events.SELECT]: (state, { selection }) => state.set('selected', selection),
  [events.LAYERS_LOADED]: (state, { layers }) => state.set('layers', layers),
  [events.SHOW_PAYLOAD]: (state, { payload }) =>
    state.set('modal', fromJS({ show: true, payload })),
  [events.CLOSE_MODAL]: (state) =>
    state.set('modal', fromJS({ show: false, payload: {} })),
});
