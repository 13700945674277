import { List, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  FIELD_SUGGESTION_VALIDATION_PENDING,
  IS_CURRENT_VALUE_FILTERS_NO,
  LANGUAGE_FRENCH,
  PAGINATION_SORT_OPTIONS,
  SOURCE_FILTER_ML,
} from 'modules/field-suggestion/constants';
import { addPathToHierarchy } from 'reducers/kinds';
import { compareNames } from 'utils';
import {
  changeLimit,
  changeSorting,
  initPagination,
  nextPage,
  previousPage,
  receiveReducer,
} from 'utils/reducers/list';

import * as events from './events';

export const FIELD_SUGGESTION_DASHBOARD_STORE_KEY = 'fieldSuggestion';

const LIMIT_STORAGE_KEY = 'fieldSuggestionListLimit';
const SORTING_STORAGE_KEY = 'fieldSuggestionListSorting';

export const freshFilters = fromJS({
  selected: [],
  languages: {},
  validationStatus: {},
  isCurrentValue: {},
  source: {},
  organizations: {},
  fieldMetaData: {},
  fieldIds: {},
  nbSources: {},
  metricValue: {},
});

const preselectedFilters = {
  selected: [
    {
      filterType: 'languages',
      filterId: LANGUAGE_FRENCH,
      filterLabel: 'languages: French',
    },
    {
      filterType: 'validationStatus',
      filterId: FIELD_SUGGESTION_VALIDATION_PENDING,
      filterLabel: 'validation status: Pending',
    },
    {
      filterType: 'isCurrentValue',
      filterId: IS_CURRENT_VALUE_FILTERS_NO,
      filterLabel: 'is current value: No',
    },
    {
      filterType: 'source',
      filterId: SOURCE_FILTER_ML,
      filterLabel: 'source: Salsify SupplierXM models',
    },
  ],
  languages: [[LANGUAGE_FRENCH, true]],
  validationStatus: [[FIELD_SUGGESTION_VALIDATION_PENDING, true]],
  isCurrentValue: [[IS_CURRENT_VALUE_FILTERS_NO, true]],
  source: [[SOURCE_FILTER_ML, true]],
};

const fieldSuggestionInitPagination = initPagination.bind(
  this,
  LIMIT_STORAGE_KEY,
  SORTING_STORAGE_KEY,
  PAGINATION_SORT_OPTIONS[0].value,
);

export const initialState = {
  list: List(),
  search: null,
  pagination: fieldSuggestionInitPagination(),

  savedProductIds: [],
  filters: freshFilters.mergeDeep(preselectedFilters),
  // show leaves for kind and internal categories
  showChildren: false,
  internalCategoriesIdToPath: {},
  // Field metadata aggregations: available FMDs and all possible values per FMD
  fieldMetaDataAggregates: List(),
  // List of PVs' content owners
  organizations: List(),
  // List of possible values for source_count filter
  sourceCount: List(),

  internalCategories: [],

  // Validation process:
  validating: false,
  validatedEntitiesIds: [],
  validationErrors: [],

  // Edition process:
  editing: false,
  editedEntitiesIds: [],
  editionErrors: [],

  // Modal state: null if closed, else the action
  modalState: null,
};

const transformStateRemoveFilterType = (state, filterType) => {
  const newState = { ...state };
  // find all positive root filters -> set false
  const [...filterIds] = newState.filters
    .get(filterType)
    .filter((value) => value)
    .keys();

  for (let i = 0; i < filterIds.length; i += 1) {
    newState.filters = newState.filters.deleteIn([filterType, filterIds[i]]);
  }

  // find all positive selected filters -> remove
  const selected = newState.filters
    .get('selected')
    .filter((e) => fromJS(e).get('filterType') !== filterType);
  newState.filters = newState.filters.set('selected', selected);

  return newState;
};

export default createReducer(initialState, {
  [events.FIELD_SUGGESTION_RECEIVE_LIST]: (state, action) => {
    const { resetPage } = action;
    const newState = { ...state };
    if (resetPage) {
      newState.pagination.totalResults = 0;
      newState.pagination.currentPage = 1;
      newState.pagination.totalPages = 0;
    }
    return receiveReducer(newState, action);
  },
  [events.FIELD_SUGGESTION_NEXT_PAGE]: nextPage,
  [events.FIELD_SUGGESTION_PREVIOUS_PAGE]: previousPage,
  [events.FIELD_SUGGESTION_CHANGE_LIMIT]: changeLimit,
  [events.FIELD_SUGGESTION_CHANGE_SORTING]: changeSorting,
  [events.FIELD_SUGGESTION_ADD_FILTER]: (state, action) => {
    const { filterType, toggleFilterType, filterId, filterLabel } = action;
    let newState = { ...state };
    if (toggleFilterType) {
      newState = transformStateRemoveFilterType(state, filterType);
    }
    if (filterType === 'fieldMetaData') {
      newState = transformStateRemoveFilterType(newState, 'fieldIds');
    }
    newState.filters = newState.filters.setIn([filterType, filterId], true).set(
      'selected',
      newState.filters.get('selected').push(
        fromJS({
          filterType,
          filterId,
          filterLabel,
        }),
      ),
    );
    return newState;
  },

  [events.FIELD_SUGGESTION_REMOVE_FILTER]: (state, action) => {
    const { filterType, filterId } = action;
    let newState = { ...state };
    // specific behaviours
    switch (filterType) {
      case 'fieldMetaData':
        newState = transformStateRemoveFilterType(newState, 'fieldIds');
        break;
      default:
        break;
    }
    // generic behaviour
    newState.filters = newState.filters.deleteIn([filterType, filterId]);
    const selected = newState.filters.get('selected').filter((e) => {
      const selectedFilter = fromJS(e);
      return !(
        selectedFilter.get('filterType') === filterType &&
        selectedFilter.get('filterId') === filterId
      );
    });
    newState.filters = newState.filters.set('selected', selected);
    return newState;
  },
  [events.FIELD_SUGGESTION_CLEAR_FILTERS]: (state) => {
    const newState = { ...state };
    newState.filters = freshFilters;
    return newState;
  },
  [events.FIELD_SUGGESTION_CHECK_ROW]: (state, action) => {
    const newState = { ...state };
    newState.list = newState.list.setIn(
      [action.index, 'checked'],
      action.checked,
    );
    return newState;
  },
  [events.FIELD_SUGGESTION_CHECK_ALL_ROWS]: (state, action) => {
    const newState = { ...state };
    newState.list = newState.list.map((e) => e.set('checked', action.checked));
    return newState;
  },
  [events.FIELD_SUGGESTION_RECEIVE_AGGREGATIONS]: (state, action) => {
    const newState = { ...state };
    // Process the contentowners
    const orgas = [];
    Object.entries(action.aggregations['metadata.contentowner.id']).forEach(
      ([, co]) => {
        const { name } = co.top_hits[0].metadata.contentowner;
        orgas.push({ id: co.id, name, label: name });
      },
    );
    newState.organizations = fromJS(orgas);

    // Process the fieldmetadata part
    const formatted = [];
    Object.entries(action.aggregations['field.fieldmetadata_id']).forEach(
      ([, aggs]) => {
        const fmd = { options: [], id: aggs.id };
        Object.entries(aggs.data).forEach(([, agg]) => {
          fmd.options.push({
            id: agg.id,
            label: agg.top_hits[0].field.field_name,
            name: agg.top_hits[0].field.field_name,
          });
          fmd.label = agg.top_hits[0].field.fieldmetadata_name;
        });
        fmd.options.sort(compareNames);
        formatted.push(fmd);
      },
    );
    newState.fieldMetaDataAggregates = fromJS(formatted);

    // Process the number of sources
    const nbSources = [];
    Object.entries(action.aggregations['metadata.source_count']).forEach(
      ([, co]) => {
        nbSources.push({ id: co.id, name: co.id, label: co.id });
      },
    );
    newState.sourceCount = fromJS(nbSources);
    return newState;
  },
  // Validation related reducers
  [events.FIELD_SUGGESTION_BULK_VALIDATION]: (state) => ({
    ...state,
    validating: true,
    validationErrors: [],
    validatedEntitiesIds: [],
  }),
  [events.FIELD_SUGGESTION_VALIDATION_PROGRESS]: (state, action) => {
    const newState = { ...state };
    if (newState.validatedEntitiesIds.indexOf(action.entityId) === -1) {
      newState.validatedEntitiesIds = [...newState.validatedEntitiesIds];
      newState.validatedEntitiesIds.push(action.entityId);
    }
    if (action.error) {
      newState.validationErrors.push(action.error);
    }
    return newState;
  },
  [events.FIELD_SUGGESTION_VALIDATION_COMPLETE]: (state) => {
    const newState = { ...state };
    newState.validating = false;
    newState.savedCount = newState.totalCount;
    return newState;
  },

  // Edition related reducers
  [events.FIELD_SUGGESTION_BULK_EDITION]: (state) => ({
    ...state,
    editing: true,
    editionErrors: [],
    editedEntitiesIds: [],
  }),
  [events.FIELD_SUGGESTION_EDITION_PROGRESS]: (state, action) => {
    const newState = { ...state };
    if (newState.editedEntitiesIds.indexOf(action.entityId) === -1) {
      newState.editedEntitiesIds = [
        ...newState.editedEntitiesIds,
        action.entityId,
      ];
    }
    if (action.error) {
      newState.editionErrors.push(action.error);
    }
    return newState;
  },
  [events.FIELD_SUGGESTION_EDITION_COMPLETE]: (state) => {
    const newState = { ...state };
    newState.editing = false;
    newState.savedCount = newState.totalCount;
    return newState;
  },

  // Modal
  [events.FIELD_SUGGESTION_OPEN_MODAL]: (state, action) => ({
    ...state,
    modalState: action.modalState,
    validatedEntitiesIds: [],
    validationErrors: [],
    editedEntitiesIds: [],
    editionErrors: [],
  }),
  [events.FIELD_SUGGESTION_CLOSE_MODAL]: (state) => ({
    ...state,
    validating: false,
    editing: false,
    modalState: null,
    validatedEntitiesIds: [],
    validationErrors: [],
    editedEntitiesIds: [],
    editionErrors: [],
  }),

  [events.FIELD_SUGGESTION_UPDATE_SEARCH]: (state, action) => ({
    ...state,
    search: action.search,
    pagination: initPagination(
      LIMIT_STORAGE_KEY,
      SORTING_STORAGE_KEY,
      PAGINATION_SORT_OPTIONS[0].value,
    ),
  }),

  [events.RECEIVE_KIND_LEAVES]: (state, action) => ({
    ...state,
    kindLeaves: action.data,
  }),

  [events.RECEIVE_SEGMENT_HIERARCHY]: (state, { organization, hierarchy }) => {
    const newState = { ...state };
    // make retailer root of its hierarchy
    const orgaHierarchy = {
      id: organization.id,
      name: organization.name,
      children: hierarchy,
    };
    // there is no concurrency of ids between retailers (segment_id)
    newState.internalCategoriesIdToPath = {
      ...state.internalCategoriesIdToPath,
      ...addPathToHierarchy(hierarchy, [], {}),
    };
    newState.internalCategories.push(orgaHierarchy);
    return newState;
  },

  [events.TOGGLE_SHOW_HIERARCHY_CHILDREN]: (state) => {
    const newState = { ...state };
    newState.showChildren = !state.showChildren;
    return newState;
  },
});
